/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/News/2024-10-14_OpenDag/1.jpg"
import foto2 from "assets/images/News/2024-10-14_OpenDag/2.jpg"
import foto3 from "assets/images/News/2024-10-14_OpenDag/3.jpg"
import foto4 from "assets/images/News/2024-10-14_OpenDag/4.jpg"
import foto5 from "assets/images/News/2024-10-14_OpenDag/5.jpg"
import foto6 from "assets/images/News/2024-10-14_OpenDag/6.jpg"
import foto7 from "assets/images/News/2024-10-14_OpenDag/7.jpg"
import foto8 from "assets/images/News/2024-10-14_OpenDag/8.jpg"
import foto9 from "assets/images/News/2024-10-14_OpenDag/9.jpg"
import foto10 from "assets/images/News/2024-10-14_OpenDag/10.jpg"
import foto11 from "assets/images/News/2024-10-14_OpenDag/11.jpg"
import foto12 from "assets/images/News/2024-10-14_OpenDag/12.jpg"
import foto13 from "assets/images/News/2024-10-14_OpenDag/13.jpg"
import foto14 from "assets/images/News/2024-10-14_OpenDag/14.jpg"
import foto15 from "assets/images/News/2024-10-14_OpenDag/15.jpg"
import foto16 from "assets/images/News/2024-10-14_OpenDag/16.jpg"
import foto17 from "assets/images/News/2024-10-14_OpenDag/17.jpg"
import foto18 from "assets/images/News/2024-10-14_OpenDag/18.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function N2024_10_14_OpenDag() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="De Grote Opening"
          description="Zaterdag 12-10-2024 was onze grote opening van het nieuwe BudoCentrum in Sliedrecht"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 01-10-2024"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                Opening Budocentrum Sliedrecht 14-10-2024
              
              
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="450em" alt="Opening" title="Foto van de opening" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                De Opening van ons nieuwe BudoCentrum
              </MKTypography>
              <p>
                Op Zaterdag 12 Oktober was de opendag van ons Nieuwe BudoCentrum.
                Op deze dag zijn heel veel geïnteresseerden komen kijken. Verder zijn er deze dag hele mooie demo's van verschillende sporten gegeven:
                <ul>
                  <li>Kyokushin Karate</li>
                  <li>Grappling</li>
                  <li>Wing Chung</li>
                  <li>Judo</li>
                  <li>Aikibudo</li>
                  <li>BJJ</li>
                  <li>Grappling</li>
                </ul>
                Bent u na deze demo's nou ook enthousiast om een van onze sporten te proberen kunt u alle informatie op deze website vinden.
              </p>
            </Grid>

            <Grid 
              container
              style={{
                backgroundColor: "#000",
                padding: "5em"
              }}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto2} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto3} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto4} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto5} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto6} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto7} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto8} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto9} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto10} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto11} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto12} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto13} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto14} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto15} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto16} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto17} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto18} size="450em" alt="Foto opendag" title="Foto opendag" />
              </Grid>
            </Grid>

          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default N2024_10_14_OpenDag;
