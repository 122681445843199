/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* EXAMPLES
<Alert variant="filled" severity="success">
  This is a filled success Alert.
</Alert>
<Alert variant="filled" severity="info">
  This is a filled info Alert.
</Alert>
<Alert variant="filled" severity="warning">
  This is a filled warning Alert.
</Alert>
*/


import Alert from "@mui/material/Alert"
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";

function SiteAlerts() {
  return (
    <>
      {/* <Alert severity="info">
        Momenteel is het vakantie. Lessen starten weer op Maandag 26-08. <i>(Vrij trainen kan natuurlijk zoals altijd in de groepsapp besproken worden)</i>
      // </Alert> <br /> */
      // <MKAlert variant="filled" color="info" severity="warning" dismissible>
      //   <p>
      //   Vanaf 27-08 zal de jeugdles in Nieuw-Lekkerland vanwege de grote van de groep gesplitst worden.<br />
      //   <u>Les 1:</u> 17:00-18:00 in de Klipper voor de leeftijden 8 t/m 10<br />
      //   <u>Les 2:</u> 18:30-19:30 in het Carillon voor de leeftijden 11 t/m 15
      //   </p>
      // </MKAlert> 
      }
      {/* <MKAlert variant="filled" color="warning" dismissible>
        <p>
        12 Oktober: Open dag van het nieuwe buducentrum. Meer info op de nieuws pagina.
        De lessen zullen hier op 14 Oktober starten volgens het nieuwe rooster
        </p>
      </MKAlert> */}
    </>
  );
}

export default SiteAlerts;
