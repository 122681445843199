/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/News/2024-10-01_OpenDag/OpenDag.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function N2024_10_01_openDag() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="Opendag nieuwe Budocentrum"
          description="Wij gaan verhuizen!! Kom jij ook naar de opening van onze nieuwe locatie"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 01-10-2024"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                OpenDag Budocentrum Sliedrecht 12-10-2024
              
              
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="350em" alt="Flyer Opening" title="Flyer van de opening nieuwe locatie" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Opening nieuwe locatie
              </MKTypography>
              <p>
                Budokai Wor & Sportschool Tromp krijgt compleet nieuw onderkomen.<br />
                Op zaterdag 12 oktober is de grote opening!! <br />
                En iedereen is van harte welkom om in ons mooie trainingscentrum te komen kijken.<br />
                <a href="https://www.facebook.com/WingChunKungFuAssociatieSliedrecht" target="_blank">
                  <MKTypography variant="body1" color="info">Associatie Wing-Chun Kung-Fu Sliedrecht/Dordrecht</MKTypography>
                </a>
                <a href="https://www.facebook.com/BudokaiWor" target="_blank">
                  <MKTypography variant="body1" color="info">Budokai wor</MKTypography>
                </a>
                <a href="https://www.facebook.com/sportschooltrompalblasserwaard" target="_blank">
                  <MKTypography variant="body1" color="info">Sportschool Tromp</MKTypography>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100063455525823" target="_blank">
                  <MKTypography variant="body1" color="info">Budo Ryu Ikigai </MKTypography>
                </a>
                Geven van alle lessen die er gegeven gaan worden een super toffe demonstratie. Ben je jong of oud en altijd al vechtsport willen doen in een gezellige en gemoedelijke omgeving? <br />
                Wacht dan niet langer. <br />
                Wij verwelkomen u 12 oktober onder het genot van een hapje en een drankje<br />
                <a href="https://www.google.com/maps/place/Professor+van+Musschenbroekstraat+4,+3362+SW+Sliedrecht/@51.8206002,4.7790208,17z/data=!3m1!4b1!4m6!3m5!1s0x47c6820cee10419d:0xa2e5b4e5ffef0d14!8m2!3d51.8206002!4d4.7815957!16s%2Fg%2F11h044qnkr?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                  <MKTypography variant="body1" color="info">Professor van Musschenbroekstraat 4</MKTypography>
                </a> 
                </p>
            </Grid>




          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default N2024_10_01_openDag;
