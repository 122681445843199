/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Carousel from "react-material-ui-carousel";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import Sporten from "assets/images/Sporten.jpg";
import PersonalTraining from "assets/images/PersonalTraining.jpg";
import Yoga from "assets/images/Yoga.jpg";
import Rol from "assets/images/Rol.jpg";
import Worp from "assets/images/Worp.jpg";
import jeugd from "assets/images/jeugd.jpg";
import wingchun from "assets/images/wingchun.jpg";


function Information() {

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={8}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="groups"
                    title="Samenwerking"
                    description="In het Budocentrum Sliederecht worden meerdere verschillende sporten gegeven. Dit wordt gedaan vanuit verschillende clubs/vereningingen, wat het centrum uniek maakt. Er zijn maar weinig locatie waar dit gebeurd. Kernwoorden die ons Budocentrum typeren zijn: Saamhorigheid, Verbinden, Ontwikkeling, Discipline en Respect"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="sports_martial_arts"
                    title="Martial Arts / Krijgskunsten"
                    description="Met Martial Arts/Krijgskunsten is er niet 1 sport of stijl de beste. Onze mentaliteit is dat deze sporten elkaar aanvullen. Daarom is het mogelijk om binnen het Budocentrum meerdere krijgskunsten te beoefenen."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="fitness_center"
                    title="Personal training"
                    description="Bij Cynergi geloven we dat een fit en gezond lichaam onlosmakelijk verbonden is met een gezonde mindset. Daarom werken we 1 op 1 of in kleine groepen, zodat we jou en jouw gewoontes beter leren kennen. Onze coaches dagen vervolgens op een leuke en ontspannen manier niet alleen je lichaam uit, maar ook je geest."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Carousel height={300} justifyContent="middle" alignItems="middle">
              <img src={Sporten} style={{ width: '100%' }}/>
              <img src={PersonalTraining}  style={{ width: '100%' }} />
              <img src={Yoga}  style={{ width: '100%' }} />
              <img src={Rol}  style={{ width: '100%' }} />
              <img src={Worp}  style={{ width: '100%' }} />
              <img src={jeugd}  style={{ width: '100%' }} />
              <img src={wingchun}  style={{ width: '100%' }} />
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
