/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/News/2024-10-01_Rooster/Rooster.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function N2024_10_01_Rooster() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="Nieuwe Rooster"
          description="Als gevolg van de verhuizing zal er vanaf 14-10-2024 een nieuw rooster ingaan"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 01-10-2024"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                Nieuwe Rooster Budocentrum Sliedrecht 14-10-2024
              
              
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="350em" alt="Flyer Opening" title="Flyer van de opening nieuwe locatie" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Nieuwe rooster
              </MKTypography>
              <p>Als gevolg van de verhuizing moeten sommige lessen veranderen van dag les tijd. Zie hiernaast het nieuwe rooster dat vanaf 14 Oktober in zal gaan</p>
            </Grid>




          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default N2024_10_01_Rooster;
