/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import highlight1 from "assets/images/News/2024-10-14_OpenDag/1.jpg"
import ImageOverlay from "components/ImageOverlay";

function Highlight() {
  return (
    <MKBox
      component="section"
      sx={{
        background: 'linear-gradient(to bottom,  #0092d2 0%,#0092d2 45%,#0c5ca3 100%)',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      color="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
        Uitgelicht
      </MKTypography>
      <Container>
        <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          <Grid item xs={12} lg={3}>
            <ImageOverlay image={highlight1} size="250em" alt="Flyer Opening" title="Flyer van de opening nieuwe locatie" />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Opening nieuwe locatie
            </MKTypography>
            <p>
                Op Zaterdag 12 Oktober was de opendag van ons Nieuwe BudoCentrum.
                Op deze dag zijn heel veel geïnteresseerden komen kijken. Verder zijn er deze dag hele mooie demo's van verschillende sporten.

                Klik op het nieuwesbericht hieronder voor meer foto's
              </p>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Highlight;
