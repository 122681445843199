/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import wor from "assets/images/BudokaiWor.jpg";
import tromp from "assets/images/SportschoolTromp.jpg";
import ikigai from "assets/images/Ikigai.jpg";
import wingchun from "assets/images/WingChunAssosiation.jpg";
import cynergi from "assets/images/Cynergi.png";
import happy from "assets/images/HappyOnTheMove.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Scholen
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              De volgende scholen zijn bij ons in het budocentrum te vinden
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                cardHeight={500}
                btnAlign={[0]}
                image={wor}
                path="https://www.budokai-wor.nl"
                name="Budokai Wor"
                position={{ color: "info", label: "Budo/Martial-Arts" }}
                description="Budokai Wor is een actieve dojo in Sliedrecht waar je op een serieuze en verantwoordelijke manier alle facetten van het Kyokushin karate, Kickboksen en Cardio Boxing kunt leren. De minimumleeftijd bij ons is (bijna) 6 jaar; er is geen maximumleeftijd. Zo worden onder meer kracht, lenigheid, lichaamscoördinatie, uithoudingsvermogen en snelheid verbeterd, maar ook het 
                             zelfvertrouwen groeit. Kyokushin Karate is voor ieder wat wils. Je kunt het recreatief beoefenen, maar het ook fanatiek en/of als wedstrijdsport beoefenen."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                cardHeight={500}
                btnAlign={[16, 13, 9.5]}
                image={tromp}
                path="https://www.sportschooltromp.nl"
                name="Sportschool Tromp"
                position={{ color: "info", label: "Budo/Martial-Arts" }}
                description="Sportschool Tromp is al bijna vijftig jaar de plek waar Budolessen aangeboden worden aan kinderen, jongeren, volwassenen en senioren uit de omgeving Sliedrecht, Papendrecht, Alblasserdam, Nieuw-Lekkerland & Streefkerk. Sportschool Tromp is één van de eerste sportscholen in Nederland die Judo, Karate en Aikibudo lessen aanbied. Sinds 2016 wordt er ook Grappling/Brazilian Jiu-Jitsu(BJJ) gegeven."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                cardHeight={500}
                btnAlign={[0,0]}
                image={ikigai}
                path="https://www.daito-ryu.nl"
                name="Ikigai"
                position={{ color: "info", label: "Daito-ryu Aiki JuJutsu" }}
                description="Het doel van Budo Ryu Ikigai is zoals de naam aangeeft, het volgen van je passie als het gaat om Japanse krijgskunsten. Hoe haal je energie uit je sport? Wat maakt het dat je er mee door wilt blijven gaan? Deze drijfveren zijn voor iedereen anders en goed in te passen binnen Budo Ryu Ikigai.Sinds 2018 zijn er wekelijkse lessen Daito-ryu Aikijujutsu in het Budocentrum 
                             Sliedrecht. Daito-ryu Aikijujutsu is een traditionele Japans vorm van Budo zoals vele honderden jaren door de Takeda familie is beoefend."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                cardHeight={500}
                btnAlign={[9.5, 6, 9.5]}
                image={wingchun}
                path="https://www.wing-chun.nl"
                name="Associatie Wing-Chun Kung-Fu"
                position={{ color: "info", label: "Wing-Chun Kung-Fu" }}
                description="Wilt u een vechtsport of zelfverdediging leren? Wilt u weerbaar worden en uw zelfvertrouwen vergroten? Bent u altijd al nieuwsgierig geweest naar Wing Chun Kung Fu / Ving Tsun Kung Fu? Of houdt u gewoon van lekker sporten? Kom dan langs op een van onze Wing Chun Kung Fu scholen. U bent welkom om een gratis proefles bij te komen wonen. Hieronder kunt u onze 
                             trainingslocaties vinden."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                cardHeight={500}
                btnAlign={[0,0]}
                image={cynergi}
                path="https://www.cynergi.nl"
                name="Cynergy"
                position={{ color: "info", label: "Personal Training, Duo Training, Small Group Training, Olympisch Weightlifting & Voedingscoaching" }}
                description="Cynergi is ontstaan vanuit passie voor sport en coaching. Vol energie de synergie aangaan met onze mensen. Vanuit een persoonlijke benadering begeleiden wij ieder in een reis richting een lifestyle die fysiek en mentaal sterker en fitter maakt.
                              Samen met de andere trainers Robert en Nelleke én met jullie doen we er alles aan om sporten bereikbaar, uitdagend en vooral ontzettend leuk te maken.
                              Jouw doel is ons doel!"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
