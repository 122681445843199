/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Sportschooltromp from "./Sportschooltromp";
import Budokaiwor from "./Budokaiwor";
import Ikigai from "./Ikigai";
import Wingchun from "./Wingchun";
import Cynergi from "./Cynergi";


function Section1() {
  return (
    <MKBox 
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container spacing={3} width="100%">
          <Grid item sx={12} xl={6} width="100%">
            <Budokaiwor />
          </Grid>
          <Grid item sx={12} xl={6} width="100%">
            <Sportschooltromp />
          </Grid>
          <Grid item sx={12} xl={6} width="100%">
            <Ikigai />
          </Grid>
          <Grid item sx={12} xl={6} width="100%">
            <Wingchun />
          </Grid>
        </Grid>
      </Container>
    </MKBox>

    
  );
}

export default Section1;